<template>
  	<div class="limiter">
		<div class="container-login100">
			<div class="wrap-login100">
                <form class="login100-form" action="#">
                    <center>
						<a-avatar loading="lazy" :size="180" :src="(empresa.imagen) ? empresa.imagen : imagenLogo" style="margin-top: -50px;"/>
					</center>
					<span class="login100-form-title p-b-43">
                        <h5 class="text-center" style="color:rgb(170, 170, 170); margin-top: 23px; font-size:25px;">BIENVENIDO</h5>
						<h4 style="color:rgb(170, 170, 170); font-weight:700; font-size:18px;">{{ utils.getEndPointSistema() }}</h4>
						<br>
					</span>

					<label v-if="!$v.form.email.required" class="control-label">
						<strong>Correo Electronico:</strong> 
						<span style="color:rgb(163, 5, 5); font-weight:700; font-size:10px;"> El campo es obligatorio</span>
					</label>
					<label v-if="!$v.form.email.email" class="control-label">
						<strong>Correo Electronico:</strong> 
						<span style="color:rgb(163, 5, 5); font-weight:700; font-size:10px;"> El formato no es válido</span>
					</label>
					<div :class="{ 'wrap-input100': 'wrap-input100', 'wrap-input100 has-error': $v.form.email.$error, 'wrap-input100 has-success': !$v.form.email.$invalid }">
						<a-input
							v-model.trim="$v.form.email.$model"
							:class="{ 'has-error': $v.form.email.$error, 'has-success': !$v.form.email.$invalid }"
							class="form-control"
							id="email"
							style="height:50px;"
							placeholder="Ingresa su Correo Electronico"
							autocomplete="off"
						/>
					</div>
					<label v-if="!$v.form.password.required" class="control-label">
						<strong>Contraseña:</strong> 
						<span style="color:rgb(163, 5, 5); font-weight:700; font-size:10px;"> El campo es obligatorio</span>
					</label>
					<label v-if="!$v.form.password.minLength" class="control-label">
						<strong>Contraseña:</strong> 
						<span style="color:rgb(163, 5, 5); font-weight:700; font-size:10px;"> El campo debe contener minimo 8 caracteres</span>
					</label>
					<div :class="{ 'wrap-input100': 'wrap-input100', 'wrap-input100 has-error': $v.form.password.$error, 'wrap-input100 has-success': !$v.form.password.$invalid }">
						<a-input-password
							v-model.trim="$v.form.password.$model"
							:class="{ 'has-error': $v.form.password.$error, 'has-success': !$v.form.password.$invalid }"
							class="form-control"
							id="password"
							style="height:50px;"
							placeholder="Ingrese su Contraseña"
							autocomplete="off"
							@keyup.enter="realizarAccion"
						/>
					</div>

					<div class="container-login100-form-btn">
						<a-button :disabled="$v.form.$invalid" class="btn login100-form-btn" style="background-color:#004bce; color:white; height:50px;" type="primary" icon="poweroff" :loading="loading" @click="iniciarSesion">
							INGRESAR AL SISTEMA
						</a-button>
					</div>
				</form>
				<div class="login100-more" :style="{ 'background-image': 'url(' + background + ')' }"></div>
			</div>
		</div>
    </div>
</template>

<script>
	import { required, minLength, email } from 'vuelidate/lib/validators'
	import * as utils from '@/utils/utils'
	import Swal from 'sweetalert2'
	import { mapGetters, mapActions } from 'vuex'
	import { 
		TOKEN, 
		USER_ID,
		ALMACEN_SISTEMA_KEY_ID,
		SISTEMA_USUARIO
	} from '../../../utils/constants'
	import Cookies from 'js-cookie'

	document.documentElement.style.setProperty('--element', '#014694')

	export default {

		data () {
			return {
				imagenLogo: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/icono.png' : '/static/assets/images/icono.png',
				background: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/control.jpg' : '/static/assets/images/control.jpg',
				utils,
				form: { 
					email: '', 
					password: ''
				},
				loading: false,
				spinnerloading: false
			}
		},

		validations: {
			form: {
				email: { required, email },
				password: { required, minLength: minLength(8) }
			}
		},

		methods: {
			...mapActions('auth', ['signIn']),
			...mapActions('empresa', ['showEmpresaWebsite']),

			realizarAccion () {
				if (this.form.password.trim() !== '') {
					this.iniciarSesion()
				}
			},
			
			iniciarSesion () {
				this.loading = true
				this.signIn(this.form).then(response => {
					this.$message.success(
						'Bien hecho! Has iniciado correctamente la sesion en el sistema...!',
						10
					)
					setTimeout(function () {
						this.loading = false
						const leaderKey = 'leader_key'
						localStorage.removeItem(leaderKey)
						
						this.$router.go()
					}.bind(this), 1300)
				}).catch(error => {
					this.loading = false
					utils.openNotificationWithIcon('error', 'Atencion!!!', error.response.data.message, 'topRight')
					this.form.user = ''
					this.form.password = ''
					this.$v.form.$reset()
				})
			}
		},

		computed: {
			...mapGetters('empresa', ['empresa'])
		},

		async mounted () {
			await this.showEmpresaWebsite()
			Cookies.remove(USER_ID)
			Cookies.remove(TOKEN)
			Cookies.remove(ALMACEN_SISTEMA_KEY_ID)
			Cookies.remove(SISTEMA_USUARIO)
		}
	}
</script>

<style>
	.background-login { background-image: url('/static/assets/images/control.jpg'); }
	.img-circle { border-radius: 50%; }
	.ant-input-affix-wrapper .ant-input-suffix {
		right: 35px;
	}
	.ant-input {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		font-variant: tabular-nums;
		list-style: none;
		font-feature-settings: 'tnum';
		position: relative;
		display: inline-block;
		width: 100%;
		height: 48px;
		padding: 4px 11px;
		padding-right: 11px;
		color: rgba(0, 0, 0, 0.65);
		font-size: 25px;
		line-height: 1.5;
		background-color: #fff;
		background-image: none;
		border: 1px solid #ffffff;
		border-radius: 4px;
		transition: all 0.3s;
	}

	.ant-input-affix-wrapper {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		color: rgba(0, 0, 0, 0.65);
		font-size: 20px;
		font-variant: tabular-nums;
		line-height: 1.5;
		list-style: none;
		font-feature-settings: 'tnum';
		position: relative;
		display: inline-block;
		width: 100%;
		text-align: start;
	}
</style>
